import React, { useEffect, useState, useContext } from 'react';
import { Tabs, Tab, Box, makeStyles } from '@material-ui/core';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { StaffingContext } from 'src/context/StaffingContext';
import { FormControlLabel } from '@mui/material';
import { IOSSwitch } from "src/views/AdminView/TeamCompliance/components";
import { clearSessionStorage } from 'src/views/AdminView/utils';

const useStyles = makeStyles(theme => ({
  toolbarContainer: {
    maxWidth: 1440,
    margin: '0 auto',
    width: '100%',
  },
  root: {
    backgroundColor: '#fff',
    marginInline: 40,
    marginTop: 10,
    fontSize: '16px',
    fontFamily: 'Poppins',
    borderBottom: '1px solid #ccc',
    position: 'sticky',
    top: 0,
    zIndex: 1,
    overflowX: 'auto',
    overflowY: 'hidden',
    '-webkit-overflow-scrolling': 'touch',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    [theme.breakpoints.down('md')]: {
      marginInline: '32px',
      paddingBottom: '10px'
    },
    [theme.breakpoints.down('sm')]: {
      marginInline: '16px',
      fontSize: '14px',
    },
  },
  tab: {
    color: '#000',
    textDecoration: 'none',
    fontFamily: 'Poppins',
    fontSize: '17px',
    minHeight: '20px !important',
    padding: '0px 15px !important',
    minWidth: 'auto',
    width: 'auto',
    borderLeft: '1px solid #ccc',
    borderRight: '1px solid #ccc',
    '&:first-child': {
      borderLeft: 'none',
      paddingLeft: '0px !important'
    },
    '&:last-child': {
      borderRight: 'none'
    },
    '&.Mui-selected': {
      color: '#000',
      fontWeight: 600
    },
    [theme.breakpoints.down('sm')]: {
      padding: '8px 12px',
      fontSize: '14px',
    }
  }
}));

function ServicesBar() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [currentBottomTab, setCurrentBottomTab] = useState('dashboard');
  const { scheduling, country } = useSelector(state => state.account.user);
  const { user } = useSelector(state => state.account);
  const { showAdminView, toggleAdminView } = useContext(StaffingContext);

  const baseNavConfig = [
    { value: 'dashboard', label: 'Dashboard', link: '/dashboard', navLink: '/dashboard' },
    { value: 'state licensing', label: 'State License', link: '/state-licensing', navLink: '/state-licensing' },
    { value: 'certificates', label: 'Certificates', link: '/certificates', navLink: 'certificates' },
    { value: 'cme compliance', label: 'CME Compliance', link: '/cme-compliance', navLink: 'compliance' },
    { value: 'esign', label: 'E-Sign', link: '/e-sign', navLink: 'e-sign' },
  ];
  
  const schedulingNavConfig = scheduling ? [
    { value: 'schedule', label: 'Schedule', link: '/schedule/my-schedule', navLink: '/schedule' },
    { value: 'expenses', label: 'Expenses', link: '/expenses/meal-expense', navLink: '/expenses' },
  ] : [];
  
  const careerNavConfig = country === 'United States' ? [
    { value: 'career', label: 'Career', link: '/career/job-board', navLink: '/career' },
  ] : [];
  
  let navConfig = [ ...baseNavConfig, ...schedulingNavConfig, ...careerNavConfig ];
  if(location.pathname.includes('/admin')){
    navConfig = [
      { value: 'dashboard', label: 'Dashboard', link: '/admin/dashboard', navLink: '/admin/dashboard' },
      { value: 'acquisition', label: 'Acquisition', link: '/admin/acquisition/state-license', navLink: '/admin/acquisition' },
      { value: 'reports', label: 'Monitoring & Renewal', link: '/admin/reports/ce_cme', navLink: '/admin/reports' }
    ];
  }

  useEffect(() => {
    const currentTab = navConfig.find(tab => location.pathname.includes(tab.navLink));
    if(location.pathname.includes('my-learning')){
      setCurrentBottomTab('certificates');
    }
    if (currentTab) {
      setCurrentBottomTab(currentTab.value);
    }
  }, [navConfig]);

  const handleBottomTabChange = (event, newValue) => {
    const newTab = navConfig.find(tab => tab.value === newValue);
    if (newTab) {
      if(location.pathname.includes('/admin')){
        clearSessionStorage();
      }
      history.push(newTab.link);
    }
  };  

  return (
    <div className={classes.toolbarContainer}>
      <Box
        className={classes.root}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Tabs
          value={currentBottomTab}
          onChange={handleBottomTabChange}
          variant="scrollable"
          scrollButtons="off"
        >
          {navConfig.map(tab => (
            <Tab
              key={tab.value}
              value={tab.value}
              label={tab.label}
              className={classes.tab}
              disableRipple
            />
          ))}
        </Tabs>
        {
          (user.role == 'admin' || user.role == 'hospital_admin' || user.role == 'department_admin') && (
            <Box style={{ fontSize: '18px', fontWeight: 500 }}>
            <FormControlLabel
              control={
                <IOSSwitch
                  checked={showAdminView}
                  onChange={toggleAdminView}
                />
              }
            />
            Admin View
            </Box>
          ) 
        }
      </Box>
    </div>
  );
}

export default ServicesBar;
